<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-row>
      <b-col cols="12">
        <b-link class="brand-logo">
        </b-link>

        <div class="p-2 p-sm-3">
          <div class="text-center">
            <h2 class="mb-1">
              Página no encontrada
            </h2>
            <p class="mb-2">
              No se ha podido encontrar el URL al que intenta acceder
            </p>

            <!-- image -->
            <img class="w-100" src="../../assets/images/logo/knblue.png">

            <b-button variant="primary" class="mb-2 mt-2 btn-sm-block" :to="{ path: '/login' }">
              Volver al home
            </b-button>
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BRow, BCol } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BRow,
    BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
